.PopupTags {

}


.PopupTags-AddColor {
    border-radius: 50%;
    height: 1em;
    width: 1em;
    margin-right: 0.5em;

    border: 3px solid var(--box-backgroundColor);
    cursor: pointer;
}

.PopupTags-ColorPicker {
    position: absolute;
    height: 88%;
    width: 40%;

    top: 5.5em;
    right: 5em;

    z-index: 2;
} 

.PopupTags-InputText {
    width: 100%;
    height: 100%;

    border: none;
    border-radius: 1em;
    padding: 0.5em;

    /*font-size: 1.2rem;
    margin: 0.5em;*/

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /*position: relative;*/
}
.PopupTags-InputText-Container {
    width: 60%;
}
.PopupBox-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}