.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}


.App-headline {
  font-size: 1.5em;
  font-weight: 500;
  margin: 0.5em;
  padding: 0;
}
.App-caption {
  font-size: 1em;
  font-weight: 300;
  margin: 0;
  padding: 0.5em;;
}