.icon-log-out {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 6px !important;
    height: 16px !important;
    border: 2px solid;
    transform: scale(var(--ggs,1));
    border-right: 0;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    margin-left: -10px   
}

.icon-log-out::after,
.icon-log-out::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute
}

.icon-log-out::after {
    border-top: 2px solid;
    border-left: 2px solid;
    transform: rotate(-45deg);
    width: 8px;
    height: 8px;
    left: 4px;
    bottom: 2px
}

.icon-log-out::before {
    border-radius: 3px;
    width: 10px;
    height: 2px;
    background: currentColor;
    left: 5px;
    bottom: 5px
} 


.icon-profile,
.icon-profile::after,
.icon-profile::before {
    display: block;
    box-sizing: border-box;
    border: 2px solid;
    border-radius: 100px
}

.icon-profile {
    overflow: hidden;
    transform: scale(var(--ggs,1));
    width: 22px !important;
    height: 22px !important;
    position: relative
}

.icon-profile::after,
.icon-profile::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 5px;
    width: 8px;
    height: 8px
}

.icon-profile::after {
    border-radius: 200px;
    top: 11px;
    left: 0px;
    width: 18px;
    height: 18px
} 


/*.icon-cloud {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 8px !important;
    height: 2px !important;
    background: var(--logo-color-light);
    margin-bottom: -20px;

    transform: scale(2);
}
   
.icon-cloud::after,
.icon-cloud::before {
    content: "";
    display: block;
    position: absolute;
    border: 2px solid;
    box-sizing: border-box;
    bottom: 0
}
   
.icon-cloud::before {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border-right: 0;
    left: -6px;
    width: 7px;
    height: 12px;

    color: var(--logo-color-light);
}
   
.icon-cloud::after {
    width: 16px;
    height: 16px;
    border-radius: 100px;
    border-left-color: transparent;
    right: -8px;
    transform: rotate(-45deg);

    color: var(--logo-color-light);
} */

.icon-close {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    width: 22px;
    height: 22px;
    border: 2px solid transparent;
    border-radius: 40px;
}

.icon-close::after,
.icon-close::before {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 16px;
    height: 2px;
    background: currentColor;
    transform: rotate(45deg);
    border-radius: 5px;
    top: 8px;
    left: 1px
}

.icon-close::after {
    transform: rotate(-45deg)
} 


/* TODO: ganz entfernen ? */