.Cardzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    height: 20em;
    width: 25em;
    
    border-radius: 20px;
    margin: 10px;
    background-color: rgba(224, 227, 230, 0.883);
    overflow: hidden;

    font-size: 0.8em;

    position: relative;
}
.Cardzone:hover{
    /*cursor: pointer;*/
    box-shadow: 5px 5px 25px rgba(224, 227, 230, 0.883), -5px -5px 25px rgba(224, 227, 230, 0.883);
    transition: background-color 0.2s linear, box-shadow 0.2s linear, border 0.2s linear;
}
.Cardzone-Header {
    position: absolute;
    top: 0;

    width: 100%;
    height: 20%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /*border-radius: 0 0 20px 20px;*/
    background-color: var(--box-backgroundColor);
}
.Cardzone-field {
    position: absolute;
    top: 20%;

    width: 100%;
    height: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /*border-radius: 0 0 20px 20px;*/

}

.PopopDevice-Icon-Placeholder {
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardfield {
    background-color: #e1e1e1;
    padding: 20px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
  }
  

  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .data-table th, .data-table td {
    padding: 10px;
    text-align: center;
  }
  
  .data-table th {
    background-color: #f1f1f1;
    font-weight: bold;
  }
  
  .data-point {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .data-label {
    color: #333;
  }
  
  .data-value {
    font-weight: bold;
  }
  .bottom-section {
    display: flex;                /* Damit die Elemente in einer Zeile erscheinen */
    justify-content: space-between; /* Abstand zwischen Alarm und Warnung */
    align-items: center;          /* Vertikale Ausrichtung */
  /* Heller Hintergrund */
    width: 100%;                  /* Sicherstellen, dass es die gesamte Breite nutzt */
    max-width: 600px;             /* Maximale Breite für das Layout */
    margin-top: 20px;             /* Abstand zum oberen Bereich */
  }
  
  .data-point-ar {
    display: flex;                /* Jedes Punkt wird als Flexbox angezeigt */
    flex-direction: column;       /* Der Text kommt untereinander */
    justify-content: center;      /* Vertikale Ausrichtung */
    align-items: center;          /* Horizontale Ausrichtung */
    cursor: pointer;             /* Zeigt an, dass es klickbar ist */
  }
  
  .data-value-ar {
    font-size: 16px;              /* Schriftgröße für die Werte */
    font-weight: bold; 
    border: solid 1px black;   
    padding: 5px;       /* Dickere Schrift für die Werte */
  }
  
  .alarm {
    color: red;                   /* Alarm in roter Farbe */
  }
  
  .warning {
    color: orange;                /* Warnung in orange */
  }
  
  .blink {
    animation: blink 1s infinite;  /* Blink-Effekt für den Alarm oder die Warnung */
  }
  
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }