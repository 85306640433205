.Header {
    background-color: white;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: calc(10px + 2vmin);
    color: black;
}
.Header-active {
    min-height: 15vh;
}

.Header-scrollDownButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 8vh;
    height: 8vh;
    border-radius: 50%;

    position: absolute;
    bottom: 2vh;
    animation: jump 6s infinite 2s ease;
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-1em);
  }
  40% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.Header-logo-active {
    width: 100%;
    height: 10vh;
    position: absolute;
    top: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font-size: calc(1px + 2vmin);

    padding-top: 1em;
}
.Header-logo-active div {
    margin: 0px 10px;
    width: 50%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Header-Logout-Button{
    height: 3em;
    width: 8em !important;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Header-Settings-Button {
    height: 3em;
    width: 3em !important;
}

.Header-logo-active div a {
    display: flex;
    flex-direction: table;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 480px) {
    .Header-Logout-Button{
        width: 2.5em !important;
        padding-right: 0.5em !important;
    }
    .Header-Logout-Button-Text {
        display: none !important;
    }
    #logoMenu {
        width: 100% !important;
    }
}
