.dot {
    width: 0.7em;
    height: 0.7em;
    border-radius: 50%;
    background-color: var(--logo-color-light); ;
    opacity: 0.3;
    margin: 1em;

    animation-name: pulse-dots;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-delay: 0s;
    animation-timing-function: ease-in-out;
}

.dot:nth-child(2) {
    animation-delay: 0.5s;
}

.dot:nth-child(3) {
    animation-delay: 1s;
}

.dot:nth-child(4) {
    animation-delay: 1.5s;
}

@keyframes pulse-dots {
    0% {
        transform: scale(0);
        opacity: 0.3;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.8;
    }
    100% {
        transform: scale(0);
        opacity: 0.3;
    }
}