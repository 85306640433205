.Login {
    height: 88vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Login-Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} 

.Login-Form-Input {
    margin: 0.5em;
    padding: 0.5em;
    /*border: 1px solid #ccc;*/
    border-radius: 4px;
    width: 70vw;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Login-Form-Input *{
    width: 20em;
    height: 5vh;
    margin: 0.3em;

    text-align: center;
    
}

.Login-Button, .Logout-Button, .Demo-Button {
    margin: 0;
    padding: 15px 32px;

    transition: background-color 1s ease;
}
.Login-Button:hover, .Logout-Button:hover, .Demo-Button:hover {
    opacity: 0.8;
}

.Demo-Button {
    margin-top: 2em;
    background-color: white;
    color: var(--logo-color-light);
    border: 1px solid var(--logo-color-light);

    padding: 13px 30px;
    margin: 3em 1em 1em 1em;
}


.forgot-password {
    margin-top: 0.5em;
    font-size: 0.6em;
    cursor: pointer;
    padding: 0.4em;
    border: none;
}
.forgot-password:hover {
    text-decoration: underline;
}