.PopupAddService {
    width: 100%;
    height: 100%;
    margin-top: 8vh;

    font-size: 0.8em;
    overflow: hidden;
   
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
@media (max-width: 600px) {
  .PopupAddService-ConfigService {
    flex-direction: column !important;
  }
}

.PopupAddService-Items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}

.PopupAddService-Items > div {
    background-color: rgba(184, 184, 184, 0.28);
    margin: 2em;
    border-radius: 0.5em;
    width: 80%;

    float: left;
}

.PopupAddService-Items > div:hover{
  cursor: pointer;
  box-shadow: 5px 5px 25px rgba(224, 227, 230, 0.883), -5px -5px 25px rgba(224, 227, 230, 0.883);
  transition: background-color 0.2s linear, box-shadow 0.2s linear, border 0.2s linear;
}

.fade-in { /*TODO: move to global css, and fix the bug where it doesn't work */
    opacity: 0;
    transition: opacity 5s ease-in;
  }
  
.fade-in.active {
  opacity: 1;
}

.PopupAddService-Icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;    

  padding: 5px;

  border-radius: 50%;
  width: 2.2em;
  height: 2.2em;
  margin: 0.5em;

  border-radius: 50%;
  font-size: 2.2em;

  color: var(--box-backgroundColor);

  border: 4px solid var(--box-backgroundColor);
}
.PopupAddService-DescriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;   
  
  margin: 0.5em;
}

.PopupAddService-Item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;    
}
.PopupAddService-Title {
  font-weight: bold;  
  font-size: 1.1em;

  margin-bottom: 0.4em;
}
.PopupAddService-Text {
  font-size: 0.9em;  
}

.PopupAddService-ConfigService {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; 
}

.PopupAddService-menuButtons {
  margin: 0em 1em;
  padding: 0.5em;

  display: inline-flex;
  flex-direction: table;
  align-items: center;
  justify-content: center;

  width: 4em;
  height: 1.8em;
}


.PopupAddService-DeviceItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left; 

  margin: 0.5em;
  padding: 0.5em;

  border-radius: 0.5em;
  background-color: rgba(184, 184, 184, 0.28);
}
.PopupAddService-DeviceItem:hover{
  box-shadow: 5px 5px 25px rgba(224, 227, 230, 0.883), -5px -5px 25px rgba(224, 227, 230, 0.883);
  transition: background-color 0.2s linear;
}

.PopupAddService-DeviceCheckbox {
  margin-right: 1em;
  cursor: pointer;
  transform: scale(1.2);
}

.PopupAddService-Right {
  justify-content: center;
}

.PopupAddService-DeviceItem-List {
  overflow-y: scroll;
}
.PopupAddService-DeviceItem-List::-webkit-scrollbar {
  display: none; /*Safari and Chrome*/
}