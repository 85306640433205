.Popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: white;
    border-radius: 1em;

    position: fixed;
    bottom: 14vh;
    left: 15vw;

    
    width: 70vw;
    height: 70vh;
    padding: 0;
    margin: 0;

    z-index: 2;
    overflow: hidden;

    border: 3px solid var(--box-backgroundColor);
}

.Popup-Text {
    width: 100%;
    margin: 1em;
    word-wrap: break-word;

}
.Popup-Headline {
    margin: 1em;
}

.Popup-closeButton {
    margin: 0;
    padding: 1em 1em;
    background-color: rgb(199, 50, 50);
    border-radius: 50%;

    position: absolute !important;
    top: 0.5em;
    right: 0.5em;
}

.Popup-Header {
    background-color: var(--box-backgroundColor);
    width: 100%;
    height: 8vh;
    position: absolute;
    top: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}