.Overview {
    width: 100%;
    height: 85vh;
}

.Overview-scrollDownButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Overview-Container {
    display: flex;
    flex-direction: center;
    align-items: center;
    justify-content: space-around;

    /*overflow-x: scroll; temporary deactivated, while not needed */
    scroll-snap-type: x mandatory;
    /* scrollbar-width: none; /* Firefox */
}
/*.Overview-Container::-webkit-scrollbar {
    display: none; Safari and Chrome
}*/
.Overview-Container > div{
    margin: 0.25em;
    scroll-snap-align: center;

    position: relative;
}

.Overview-Bubble {
    font-size: 2em;
    padding: 0.1em;
    height: 3em;
    width: 3em;
    border-radius: 20%;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    position: relative;
  
    background-color: var(--box-backgroundColor);
    color: white;
    /*background-color: #115577;
    box-shadow: 5px 5px 25px #1a80b3, -5px -5px 25px #1a80b3;
    transition: background-color 1s linear, box-shadow 1s linear, border 1s linear;
  
    animation: blinking 1.5s linear infinite;*/
}
.Overview-Bubble:hover{
    cursor: pointer;
    box-shadow: 5px 5px 25px var(--box-backgroundColor), -5px -5px 25px var(--box-backgroundColor);
    transition: background-color 0.2s linear, box-shadow 0.2s linear, border 0.2s linear;
}

@keyframes blinking {
    0% { box-shadow: 5px 5px 35px #1a80b3, -5px -5px 35px #1a80b3; }
    50% { box-shadow: 5px 5px 20px #1a80b3, -5px -5px 20px #1a80b3; }
    100% { box-shadow: 5px 5px 35px #1a80b3, -5px -5px 35px #1a80b3; }
}

.Overview-Bubble-svg-container {
    height: 2em;
    width: 2em;
}   

.Overview-Bubble-count {
    position: absolute;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 0;
    bottom: -0.3em;
    right: -0.3em;

    background-color: var(--color-green-light);
    border-radius: 50%;

    height: 1em;
    width: 1em;
    padding: 0.2em;

    font-size: 1.5em;
    color: white;
    z-index: 1;

    cursor: pointer;
    overflow: hidden;
}


.Overview-Bubble-Cloud {
    margin: 0em 0em 0em 0.5em;
    padding: 0em;
    height: 8em;
    width: 8em;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Overview-Bubble-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
}

.Overview-Dots-Container {
    display: flex;
    flex-direction: center;
    align-items: center;
    justify-content: space-around;

    height: 15vh;
    padding-top: 0.1em
}
.Overview-Dots-Container>div:nth-child(1) {
    transform: translateX(5em) rotate(-30deg);
}
.Overview-Dots-Container>div:nth-child(3) {
    transform: translateX(-5em) rotate(30deg);
}


.Overview-Bubble-Cloud svg path {
    animation-name: pulse-cloud;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
}

@keyframes pulse-cloud {
    0% {
        fill: var(--logo-color-light);
    }
    50% {
        fill: var(--box-backgroundColor);
    }
    100% {
        fill: var(--logo-color-light);
    }
}


.Overview-scrollDownButton {
    background-color: var(--box-backgroundColor);

    animation: jump 6s infinite 2s ease, pulse-arrow 3s infinite 2s ease-in-out;
}

@keyframes pulse-arrow {
    0% {
        background-color: var(--logo-color-light);
    }
    50% {
        background-color: var(--box-backgroundColor);
    }
    100% {
        background-color: var(--logo-color-light);
    }
}

.Overview-Cloud-Titles {
    display: flex;
    flex-direction: center;
    align-items: center;
    justify-content: center;

    width: 100%;
    font-size: 0.7em;
    color: white;
}

.Overview-Cloud-Titles div {
    background-color: var(--box-backgroundColor);
    padding: 0.4em;
    width: 5em;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 1em;
    animation: pulse-arrow 3s infinite ease-in-out;
}

.Overview-Cloud-Titles>div:nth-child(1) {
    transform: translate(-4em, 5em);
}
.Overview-Cloud-Titles>div:nth-child(2) {
    transform: translate(0em, 1em);
}
.Overview-Cloud-Titles>div:nth-child(3) {
    transform: translate(4em, 5em);
}

.Overview-Bubble-Add-Button {
    position: absolute;

    height: 1.5em;
    width: 1.5em;

    background-color: var(--color-green-light);

    animation: add-icon-blink 12s infinite linear;
}
@keyframes add-icon-blink {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.Overview-Bubble-Cloud-Error svg path {
    animation-name: pulse-cloud-error !important;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-delay: 1s;
    animation-timing-function: ease-in-out;
}


@keyframes pulse-cloud-error {
    0% {
        fill: var(--logo-color-light);
    }
    50% {
        fill: red;
    }
    100% {
        fill: var(--logo-color-light);
    }
}