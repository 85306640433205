body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: calc(8px + 2vmin);
  box-sizing: border-box;

  
}
html {
  scroll-behavior: smooth;
  --logo-color-light: #1b5778;
  --box-backgroundColor: rgba(62, 125, 209, 0.91);
  --color-green-light: rgb(36, 208, 116);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

.button {
  background-color: var(--logo-color-light); 
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;

  border-radius: 8px;
}
.button:hover {
  opacity: 0.8;
}

::placeholder {
  color: #1b5778;
  text-align: center;
}