.Dashboard {
    min-height: 100vh;
    margin-bottom: 1em;
}

.GatewaysDash {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
