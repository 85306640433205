.Popup-Box-Big {
    background-color: rgba(184, 184, 184, 0.28);
    border-radius: 0.5em;
    padding: 1em;

    font-size: 1.2rem;
    margin: 0.5em;

    /*min-height: 20%;*/
    width: 40%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
}
.Popup-Box-Small {
    background-color: rgba(184, 184, 184, 0.28);
    border-radius: 0.5em;
    padding: 1em;

    font-size: 1.2rem;
    margin: 0.5em;

    /*min-height: 20%;*/
    width: 20%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
}

.PopupBox-Title {
    font-weight: bold;
    margin-bottom: 0.5em;
}

.PopupText a {
    color: var(--box-backgroundColor);
    text-decoration: none;
}