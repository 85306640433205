.Card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    height: 16em;
    width: 12.5em;
    
    border-radius: 20px;
    margin: 10px;
    background-color: rgba(224, 227, 230, 0.883);
    overflow: hidden;

    font-size: 0.8em;

    position: relative;
}
.Card:hover{
    cursor: pointer;
    box-shadow: 5px 5px 25px rgba(224, 227, 230, 0.883), -5px -5px 25px rgba(224, 227, 230, 0.883);
    transition: background-color 0.2s linear, box-shadow 0.2s linear, border 0.2s linear;
}
.Card-Header {
    position: absolute;
    top: 0;

    width: 100%;
    height: 20%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /*border-radius: 0 0 20px 20px;*/
    background-color: var(--box-backgroundColor);
}

.PopopDevice-Icon-Placeholder {
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}