.PopupDevice {
  overflow: hidden;

  width: 100%;
  height: 100%;
  margin-top: 8vh;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .Popup {
    height: 70vh;
    width: 90vw;

    bottom: 15vh;
    left: 5vw;
  }
  .PopupDevice {
    flex-direction: column;
  }
  
  .PopupDevice-Left, .PopupDevice-Right {
    width: 100% !important;
    margin: 0 !important;
  }
  .PopupDevice-Left {
    width: 100% !important;
    margin: 0 !important;
    height: 45% !important;
  }.PopupDevice-Right {
    width: 100% !important;
    margin: 0 !important;
    height: 50% !important;
  }
  .Popup-Box-Big {
    width: 60% !important;
  }
  .PopupDevice-unitButtonsContainer {
    margin: 0 !important;
  }
  .PopupDevice-EmojiPicker {
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    z-index: 2 !important;
  }
}

.PopupDevice-unitButtons {
  padding: 5px;

  border-radius: 50%;
  width: 2.2em;
  height: 2.2em;
  margin: 0.5em;

  /*float: left;*/

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--box-backgroundColor);
}

.PopupDevice-unitButtonsContainer {
  width: 100%;
  margin: 1em 0em;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.PopupDevice-EmojiPicker {
  position: absolute;
  height: 88%;
  width: 40%;
  
  bottom: 0;
  left: 0;
}

.PopupDevice-Left {
  height: 100%;
  width: 40%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PopupDevice-Right {
  height: 100%;
  width: 60%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  overflow-y: scroll;
}
.PopupDevice-Right::-webkit-scrollbar {
    display: none; /*Safari and Chrome*/
}

.PopupDevice-EmojiIcon-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;    

    padding: 5px;

    border-radius: 50%;
    width: 5em;
    height: 5em;
    margin: 0.5em;

    border-radius: 50%;

    color: var(--box-backgroundColor);

    border: 4px solid var(--box-backgroundColor);
    cursor: pointer;
}

.PopupDevice-EmojiIcon {
    font-size: 2.5em;
    line-height: 2.5em;
}