.Footer {
    width: 100%;
    /*height: 10vh;*/
    padding-top: 2vh;
    /*margin-top: 1em;*/
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: rgb(25, 25, 25);
    color: white;

    font-size: 0.8em;
}

.Footer-Menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.Footer-Menu div{
    padding: 1em;
}
.Footer-Menu div:hover{
    color: rgb(62, 125, 209);
    cursor: pointer;
}